class SmoothScroll {
  constructor() {
    console.log('>>> New SmoothScroll');

    $('.js-smoothscroll').on('click', function (e) {
      e.preventDefault();

      let target = this.hash,
          $target = $(target);

      $('html, body').stop().animate({
        'scrollTop': $target.offset().top - 0
      }, 2000, 'swing', function () {
        // window.location.hash = target;
      });
    });
  }
}

export default SmoothScroll;
